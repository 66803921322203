//
// Root
//

// Light mode
@include color-mode(light) {
    // Page bg
    --#{$prefix}page-bg: #{$page-bg};

    // App blank bg color
    --#{$prefix}app-blank-bg: #{$app-blank-bg-color};

    // Header
    --#{$prefix}header-desktop-fixed-bg-color: #{get($header-config, desktop, fixed, bg-color)};
    --#{$prefix}header-desktop-fixed-box-shadow: #{get($header-config, desktop, fixed, box-shadow)};

    --#{$prefix}header-desktop-fixed-bg-color: #{get($header-config, tablet-and-mobile, fixed, bg-color)};
    --#{$prefix}header-desktop-fixed-box-shadow: #{get($header-config, tablet-and-mobile, fixed, box-shadow)};

    // Aside
    --#{$prefix}aside-bg-color: #{get($aside-config, bg-color)};

    // Sidebar
    --#{$prefix}sidebar-bg-color: #{get($sidebar-config, bg-color)};
    --#{$prefix}sidebar-tabs-link-bg-color-default: #{get($sidebar-config, tabs, link-bg-color, default)};
    --#{$prefix}sidebar-tabs-link-bg-color-active: #{get($sidebar-config, tabs, link-bg-color, active)};
    --#{$prefix}sidebar-tabs-link-icon-color-default: #{get($sidebar-config, tabs, link-icon-color, default)};
    --#{$prefix}sidebar-tabs-link-icon-color-active: #{get($sidebar-config, tabs, link-icon-color, active)};
    --#{$prefix}sidebar-dashed-border-color: #{get($sidebar-config, dashed-border-color)};
    --#{$prefix}sidebar-muted-color: #{get($sidebar-config, muted-color)};
    --#{$prefix}sidebar-muted-bg-color: #{get($sidebar-config, muted-bg-color)};
}

// Dark mode
@include color-mode(dark) {
    // Page bg
    --#{$prefix}page-bg: #{$page-bg-dark};

    // App blank bg color
    --#{$prefix}app-blank-bg: #{$app-blank-bg-color-dark};

    // Header
    --#{$prefix}header-desktop-fixed-bg-color: #{get($header-config, desktop, fixed, bg-color-dark)};
    --#{$prefix}header-desktop-fixed-box-shadow: #{get($header-config, desktop, fixed, box-shadow-dark)};

    --#{$prefix}header-desktop-fixed-bg-color: #{get($header-config, tablet-and-mobile, fixed, bg-color-dark)};
    --#{$prefix}header-desktop-fixed-box-shadow: #{get($header-config, tablet-and-mobile, fixed, box-shadow-dark)};

    // Aside
    --#{$prefix}aside-bg-color: #{get($aside-config, bg-color-dark)};

    // Sidebar
    --#{$prefix}sidebar-bg-color: #{get($sidebar-config, bg-color-dark)};
    --#{$prefix}sidebar-tabs-link-bg-color-default: #{get($sidebar-config, tabs, link-bg-color, default-dark)};
    --#{$prefix}sidebar-tabs-link-bg-color-active: #{get($sidebar-config, tabs, link-bg-color, active-dark)};
    --#{$prefix}sidebar-tabs-link-icon-color-default: #{get($sidebar-config, tabs, link-icon-color, default-dark)};
    --#{$prefix}sidebar-tabs-link-icon-color-active: #{get($sidebar-config, tabs, link-icon-color, active-dark)};
    --#{$prefix}sidebar-dashed-border-color: #{get($sidebar-config, dashed-border-color-dark)};
    --#{$prefix}sidebar-muted-color: #{get($sidebar-config, muted-color-dark)};
    --#{$prefix}sidebar-muted-bg-color: #{get($sidebar-config, muted-bg-color-dark)};
}
