.ReactTable {
  tbody {
    tr:hover {
      background: var(--#{$prefix}gray-100);
    }
    tr.fw-bolder.text-center:hover {
      background: none;
    }
  }
}
