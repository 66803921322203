// .dot-pulse {
//   position: relative;
//   left: -9999px;
//   width: 10px;
//   height: 10px;
//   border-radius: 5px;
//   background-color: #9880ff;
//   color: #9880ff;
//   box-shadow: 9999px 0 0 -5px;
//   animation: dot-pulse 1.5s infinite linear;
//   animation-delay: 0.25s;

//   &::before,
//   &::after {
//     content: '';
//     display: inline-block;
//     position: absolute;
//     top: 0;
//     width: 10px;
//     height: 10px;
//     border-radius: 5px;
//     background-color: #9880ff;
//     color: #9880ff;
//   }

//   &::before {
//     box-shadow: 9984px 0 0 -5px;
//     animation: dot-pulse-before 1.5s infinite linear;
//     animation-delay: 0s;
//   }

//   &::after {
//     box-shadow: 10014px 0 0 -5px;
//     animation: dot-pulse-after 1.5s infinite linear;
//     animation-delay: 0.5s;
//   }
// }

// @keyframes dot-pulse {
//   0% {
//     box-shadow: 9999px 0 0 -5px;
//   }
//   30% {
//     box-shadow: 9999px 0 0 2px;
//   }
//   60%,
//   100% {
//     box-shadow: 9999px 0 0 -5px;
//   }
// }
.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #9880ff;
}

.dot-container .dot:nth-last-child(1) {
  animation: jumpingAnimation 0.6s 0.1s ease-in infinite;
}
.dot-container .dot:nth-last-child(2) {
  animation: jumpingAnimation 0.6s 0.2s ease-in infinite;
}
.dot-container .dot:nth-last-child(3) {
  animation: jumpingAnimation 0.6s 0.3s ease-in infinite;
}

@keyframes jumpingAnimation {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 6px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
