#model-detail {
  .nav-item.dropdown {
    .dropdown-toggle.nav-link.active {
      color: #009ef7;
      &::after {
        color: #009ef7;
      }
    }
  }
}
